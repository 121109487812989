<template>
  <div>
    <ed-campo :intFormularioId="$route.params.intId" :key="$root.$session.versao"/>
  </div>
</template>

<script>
import EdCampo from '@/components/cadastro/formularioPersonalizado/editar'

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: {EdCampo },
  mounted() {
    if(!this.$route.params.intId){
      this.$router.push({ name: "cadastro.formulario.register" });
    }
  },
  created() {},
  data() {
    return {
    };
  },
  methods: {
  
  },
};
</script>